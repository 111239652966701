@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-family: "Open Sans", sans-serif;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden !important;
}

button {
  cursor: pointer;
}

.internModal,
.modal {
  min-width: 500px;
}

.alertModal {
  padding: 0;
}

.intern-management table td {
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
}
